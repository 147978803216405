<template>
  <template v-for="button in this.$customHeaderData.livechat.buttons">
    <beekman-buttons :as-link="true" :styling="'secondary ml-2'" :button="button" :buttons-disabled="!this.$customHeaderData.livechat.canActivate"
                     @refresh="refresh()"/>
  </template>
</template>

<script>
import BeekmanButtons from "@ui/Components/Buttons/Buttons.vue";

export default {
  components: {
    BeekmanButtons,
  },
  methods: {
    refresh() {
      window.location.reload();
    },
  },
}
</script>