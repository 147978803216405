<template>
  <beekman-button :class="classes()" :disabled="isDisabled()" :element="element" :styling="styling"
                  :type="type"
                  @click="confirmButton">
    <slot></slot>
    <template v-if="shouldConfirm">
      <beekman-confirm-button-modal :processing="processing"
                                    :show="shouldConfirm"
                                    :text="followUpText ? followUpText : confirmText"
                                    :fields="Object.keys(followUpFields).length ? followUpFields : fields"
                                    :fields-data="fieldsData"
                                    :auto-focus-confirm="autoFocusConfirm"
                                    :route-confirm-data="routeConfirmData"
                                    :content="content"
                                    :config="config"
                                    @confirmed="confirmed"
                                    @unconfirmed="destroy"/>
    </template>
  </beekman-button>
</template>

<script>
import BeekmanButton from "@ui/Components/Button.vue";
import BeekmanConfirmButtonModal from "@crud/Modals/ConfirmButton.vue";
import EventBus from "@ui/eventBus.js";

export default {
  components: {
    BeekmanButton,
    BeekmanConfirmButtonModal,
  },
  emits: [
    'created',
    'unconfirmed',
    'confirmed',
    'loading',
    'confirmSuccess',
    'confirmError',
    'confirming'
  ],
  data() {
    return {
      shouldConfirm: false,
      processing: false,
      followUpText: '',
      followUpFields: {},
    };
  },
  created() {
    this.$emit('created');
  },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    asButton: {
      type: Boolean,
      default: false,
    },
    autoFocusConfirm: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    element: {
      type: String,
      default: 'button',
    },
    styling: {
      type: [String, Object],
      default: 'secondary',
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: String,
      default: '',
    },
    onConfirmRouteParams: {
      type: Object,
      default: {},
    },
    eventAfterSuccess: {
      type: String,
      default: '',
    },
    fields: {
      type: Object,
      default: {},
    },
    fieldsData: {
      type: Object,
      default: {}
    },
    routeConfirmData: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: [],
    },
  },
  methods: {
    confirmButton() {
      this.shouldConfirm = this.confirm;
    },
    isDisabled() {
      return this.processing || this.disabled;
    },
    destroy() {
      this.resetFollowUps();
      this.processing = false;
      this.shouldConfirm = false;
      this.$emit('unconfirmed');
      this.$emit('confirming', false);
    },
    confirmed(event) {
      this.$emit('confirmed', event);

      if (this.onConfirm.length) {
        this.runOnConfirm(event);
      }
      this.resetFollowUps();
      this.processing = false;
      this.shouldConfirm = false;
    },
    resetFollowUps() {
      this.followUpText = '';
      this.followUpFields = {};
    },
    runOnConfirm(data) {
      let params = Object.assign({}, data);
      this.processing = true;
      this.$emit('loading', true);
      this.$emit('confirming', true);
      axios.post(this.$route(this.onConfirm, this.onConfirmRouteParams), params)
          .then((response) => {
            if (response.data.followUp) {
              this.followUpText = response.data.text;
              this.processing = false;
              this.shouldConfirm = true;
              if (response.data.fields) {
                this.followUpFields = response.data.fields;
              }
            } else {
              this.$emit('loading', false);
              this.$emit('confirming', false);
              if (response.data.success) {
                if (response.data.message) {
                  this.$flashSuccess(response.data.message);
                  this.$emit('confirmSuccess', {message: response.data.message});
                }
                
                if (this.eventAfterSuccess) {
                  EventBus.$emit(this.eventAfterSuccess, response.data);
                }
              }
              if (!response.data.success && response.data.message) {
                this.$flashError(response.data.message);
                this.$emit('confirmError', {message: response.data.message});
              }
              if (response.data.error) {
                this.$flashError(response.data.error);
                this.$emit('confirmError', {message: response.data.error});
              }
              if (response.data.download) {
                window.open(this.$route('export.downloadAndDelete', {'id': response.data.download}), '_blank');
              }
              if (response.data.base64Download && response.data.fileName) {
                const downloadLink = document.createElement('a');
                downloadLink.href = response.data.base64Download;
                downloadLink.download = response.data.fileName;
                downloadLink.click();
              }
              this.destroy();
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$flashError(error.response.data.message);
              this.$emit('confirmError', {message: error.response.data.message});
            }
          });
    },
    classes() {
      let classes = [];
      classes.push(`focus:outline-none transition ease-in-out duration-150`);
      if (this.asButton) {
        classes.push(`w-full inline-flex items-center px-4 py-2
bg-white border border-gray-300 rounded text-xs
text-gray-700 uppercase tracking-widest shadow-sm hover:text-gray-500 active:text-gray-800 active:bg-gray-50`);
      }
      if (this.isDisabled()) {
        classes.push('opacity-50 hover:opacity-75 cursor-not-allowed');
      } else {
        classes.push('cursor-pointer');
      }
      return classes;
    }
  },
}
</script>
