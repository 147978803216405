<template>
  <div v-if="loaded" :style="style" :class="{'dark': darkMode}">
    <div :class="[usingLiveData.length ? 'development' : '', menuCollapsed ? 'menu-collapsed' : '']"
         class="min-h-screen bg-blue-50">
      <div v-if="usingLiveData.length" class="using-live-data">
          <span class="animate-pulse">
             <i class="fas fa-exclamation mr-1"></i> LIVE DATA <i class="fas fa-exclamation mx-1"></i>
              <template v-for="data in usingLiveData">
                 - {{ data }}
              </template>
              - <i class="fas fa-exclamation mx-1"></i> LIVE DATA <i class="fas fa-exclamation ml-1"></i>
          </span>
      </div>
      <nav class="bg-theme shadow-md">
        <!-- Primary Navigation Menu -->
        <div class="mx-auto px-4">
          <div class="flex justify-between h-16">
            <div class="flex items-center">
              <a :href="this.$route('dashboard')">
                <beekman-custom-logo class="fill-white h-7 mr-4 mt-1"/>
              </a>
              <!-- Logo -->
              <div class="shrink-0 flex items-center">
                <a :href="this.$route('dashboard')">
                  <beekman-logo class="block h-9 w-auto"/>
                </a>
              </div>
            </div>

            <div class="flex items-center">
              <beekman-custom-header/>
              <beekman-disguised/>
              <beekman-select-app/>
              <beekman-notifications/>
              <beekman-exports/>
              <!-- Settings Dropdown -->
              <div class="ml-3 relative">
                <beekman-dropdown align="right" width="48" :content-classes="'bg-white max-h-96 overflow-y-auto'">
                  <template #trigger>
                                      <span class="inline-flex">
                                        <beekman-nav-user/>
                                      </span>
                  </template>

                  <template #content>
                    <beekman-nav-dropdown/>
                  </template>
                </beekman-dropdown>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!-- Page Heading -->
      <header class="bg-white shadow breadcrumbs">
        <button class="button-menu-collapse hover:bg-gray-300 border-r border-gray-300" type="button" @click="menuCollapse()">
          <i :class="'fas fa-angle-' + (menuCollapsed ? 'right' : 'left')"></i>
        </button>
        <div class="py-2 px-6 flex justify-between">
          <ul class="flex text-gray-500">
            <li class="inline-flex items-center">
              <a :href="this.$route('dashboard')">
                <i class="fal fa-home text-gray-500 hover:text-theme"></i>
              </a>
            </li>
            <beekman-breadcrumb-link v-for="(text, link) in $page.props.breadcrumbs" :key="text" :href="link != 'last' ? link : null">
              {{ text }}
            </beekman-breadcrumb-link>
          </ul>
          <beekman-favorites v-if="hasFavorites"/>
        </div>
      </header>
      <div class="flex">
        <beekman-side-navbar :menuCollapsed="menuCollapsed" :menuItems="menu" :searchMenu="searchMenu"/>
        <!-- Page Content -->
        <main class="main">
          <slot></slot>
        </main>
      </div>
      <!--Modal Portal-->
      <portal-target multiple name="modal"></portal-target>
      <!--Lightbox Portal-->
      <portal-target name="lightbox"></portal-target>
      <!-- Flash Messages -->
      <beekman-flash-messages :flash="$page.props.flash"/>
      <!-- Debugger -->
      <beekman-debugger/>
      <!-- confirm shortcut redirest -->
      <template v-if="confirmShortCut">
        <beekman-confirmation-modal :show="confirmShortCut" @close="cancelConfirm">
          <template #content>
            <p>Je hebt een shortcut geactiveerd, weet je zeker dat je deze wilt gebruiken?</p>
          </template>
          <template #footerButtons>
            <beekman-button styling="secondary" @click="cancelConfirm">
              {{ trans('global.no') }}
            </beekman-button>
            <beekman-button :autofocus="true" styling="danger" class="ml-2" @click="confirmed">
              {{ trans('global.yes') }}
            </beekman-button>
          </template>
        </beekman-confirmation-modal>
      </template>
      <beekman-direct-upload-modal v-if="directUpload.can && directUpload.show && directUpload.blobImage"
                                   :show="directUpload.show"
                                   :blobImage="directUpload.blobImage"
                                   :applicationId="application.id"
                                   @close="resetDirectUpload"/>
    </div>
  </div>
</template>
<script>
import BeekmanLogo from '@ui/Components/Logo.vue';
import BeekmanDropdown from '@ui/Components/Dropdown.vue';
import BeekmanNavDropdown from '@ui/Components/NavDropdown.vue';
import BeekmanButton from "@ui/Components/Button.vue";
import BeekmanNavUser from '@ui/Components/NavUser.vue';
import BeekmanDisguised from '@ui/Components/Disguised.vue';
import BeekmanNotifications from '@ui/Components/Notifications.vue';
import BeekmanExports from '@ui/Components/Exports.vue';
import BeekmanSelectApp from '@application/Components/SelectApp.vue';
import BeekmanSideNavbar from '@ui/Components/SideNavbar.vue';
import BeekmanBreadcrumbLink from '@ui/Components/BreadcrumbLink.vue';
import BeekmanFlashMessages from '@ui/Components/FlashMessages.vue';
import BeekmanDebugger from '@ui/Components/Debugger.vue';
import BeekmanFavorites from '@favorites/Components/Favorites.vue';
import BeekmanConfirmationModal from '@ui/Components/Modals/ConfirmationModal.vue';
import BeekmanCustomHeader from '@/Components/CustomHeader.vue';
import BeekmanCustomLogo from '@/Components/CustomLogo.vue';
import BeekmanDirectUploadModal from "@ui/Components/Modals/DirectUpload.vue";
import {mapState, mapActions} from 'pinia';
import {userStore} from '@ui/Stores/User.js';

export default {
  data() {
    return {
      accentColor: '#003a75',
      usingLiveData: [],
      menu: [],
      hasFavorites: false,
      loaded: false,
      loading: false,
      menuCollapsed: false,
      confirmShortCut: false,
      confirmShortCutRoute: '',
      searchMenu: false,
      directUpload: {
        open: false,
        can: false,
        blobImage: '',
      },
    };
  },
  mounted() {
    // Theme
    // this.accentColor = document.querySelector("meta[name='theme']").getAttribute('content');
    // Run Init
    axios.get('/init')
        .then((response) => {
          if (response && response.data) {
            this.loaded = true;

            if (response.data.translations) {
              this.$mergeTranslations(response.data.translations);
            }
            if (response.data.locale) {
              this.$addLocale(response.data.locale);
            }
            if (response.data.user) {
              window.user = response.data.user;
              this.$addUser(response.data.user);
            }
            if (response.data.menu) {
              this.menu = response.data.menu;
            }
            if (response.data.searchMenu) {
              this.searchMenu = response.data.searchMenu;
            }
            if (response.data.usingLiveData) {
              this.usingLiveData = response.data.usingLiveData;
            }
            if (response.data.customHeaderData) {
              this.$addCustomHeaderData(response.data.customHeaderData);
            }
            if (response.data.canDirectUpload) {
              this.directUpload.can = response.data.canDirectUpload;
            }
            if (response.data.applications) {
              this.setApplications(response.data.applications);
            }
            if (response.data.hasFavorites) {
              this.hasFavorites = response.data.hasFavorites;
            }
            // Menu
            this.menuCollapsed = this.$getUserPreference('menuCollapsed');
            this.setGUID();
          }
        });
  },
  components: {
    BeekmanLogo,
    BeekmanDropdown,
    BeekmanNavDropdown,
    BeekmanNavUser,
    BeekmanDisguised,
    BeekmanExports,
    BeekmanSideNavbar,
    BeekmanBreadcrumbLink,
    BeekmanFlashMessages,
    BeekmanCustomLogo,
    BeekmanCustomHeader,
    BeekmanNotifications,
    BeekmanFavorites,
    BeekmanDebugger,
    BeekmanConfirmationModal,
    BeekmanButton,
    BeekmanSelectApp,
    BeekmanDirectUploadModal,
  },
  computed: {
    style() {
      return {
        '--color-theme': this.application.color,
        '--color-theme-text': this.application.text,
      };
    },
    ...mapState(
        userStore, ['application', 'theme', 'darkMode'],
    ),
  },
  methods: {
    ...mapActions(userStore, {
      setApplications: 'setApplications'
    }),
    cancelConfirm() {
      this.confirmShortCut = false;
      this.confirmShortCutRoute = '';
    },
    confirmed() {
      window.location.assign(this.confirmShortCutRoute);
      this.confirmShortCutRoute = '';
      this.confirmShortCut = false;
    },
    handleKeydown(event) {
      // ALT + numeric
      if (event.altKey && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) {
        let key = event.key;
        if (key == 0) {
          key = 10;
        }

        if (key <= 10) {
          axios.post(this.$route('shortcut'), {key: key}).then((response) => {
            if (response.data.route) {
              if (window.modals.active === '' || window.modals.active === undefined) {
                window.location.assign(response.data.route);
              } else {
                this.confirmShortCut = true;
                this.confirmShortCutRoute = response.data.route;
              }
            }
            if (response.data.noRoute) {
              this.$flashError(`Alt + ${event.key} heeft geen shortcut`);
            }
          });
        }
      }
    },
    menuCollapse() {
      this.menuCollapsed = !this.menuCollapsed;
      this.$addUserPreference('menuCollapsed', this.menuCollapsed);
    },
    started() {
      this.loading = true;
    },
    finished() {
      this.loading = false;
    },
    setGUID() {
      /**
       * Give each window (tab) a unique identifier
       */
      if (!window.name.match(/^GUID-/)) {
        let generateUUID = () => { // Public Domain/MIT
          var d = new Date().getTime();
          //use high-precision timer if available
          if (typeof performance !== 'undefined' && typeof performance.now === 'function') d += performance.now();
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
          });
        };
        window.name = 'GUID-' + generateUUID();
      }
      // set application id
      window.application = this.application.id;
      // expire after 3 days
      var expires = (new Date(Date.now() + 86400 * 1000 * 3)).toUTCString();

      // Delete GUID cookie
      document.cookie = `GUID=;Path=/;Domain=${location.host};expires=${expires};`;
      // expire date als dit vaker mis gaat
      document.cookie = `${window.name}=;Path=/;Domain=${location.host};expires=${expires};`;
      window.addEventListener('beforeunload', function () {
        // expire after 1 day
        var expires = (new Date(Date.now() + 86400 * 1000)).toUTCString();
        document.cookie = `GUID=${window.name};Path=/;Domain=${location.host};`;

        // get all items from sessionStorage
        let sessionStorageData = {};
        for (const [key, value] of Object.entries(sessionStorage)) {
          sessionStorageData[key] = value;
        }
        document.cookie = `${window.name}=${btoa(JSON.stringify(sessionStorageData))};Path=/;Domain=${location.host};expires=${expires};`;
      });
    },
    pasteImageUpload(event) {
      if (!this.$customPasteImageUpload && this.directUpload.can) {
        let item = Array.from(event.clipboardData.items).find(x => /^image\//.test(x.type));
        if (typeof item != 'undefined') {
          this.directUpload.show = true;
          this.directUpload.blobImage = item.getAsFile();
        }
      }
    },
    resetDirectUpload() {
      this.directUpload.show = false;
      this.directUpload.blobImage = '';
    }
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
    window.addEventListener('keyup', this.handleKeyup, null);
    window.addEventListener('paste', this.pasteImageUpload, null);
    document.addEventListener('inertia:start', this.started, null);
    //document.addEventListener('inertia:progress', this.progress, null);
    document.addEventListener('inertia:finish', this.finished, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
    window.removeEventListener('paste', this.pasteImageUpload);
  }
}
</script>
