<template>
  <beekman-confirmation-modal :show="show && !confirmed" @close="unconfirm" v-if="!loading">
    <template #content>
      <p v-if="text" class="mb-5">{{text}}</p>
      <div v-if="contentHtml" v-html="contentHtml" class="w-full mb-5"></div>
      <template v-if="Object.keys(getFormFields()).length" v-for="fields in getFormFields()">
        <template v-for="field in fields">
          <template v-for="(config, name) in field">
            <div class="my-2" v-if="config.type === 'checkbox'">
              <beekman-checkbox :id="name" v-model:checked="params[name]" :indeterminateData="fieldsData" value="1"/>
              <label class="ml-2" :for="name">{{config.title}}</label>
            </div>
            <template v-if="config.type === 'text' || config.type === 'number'">
              <div class="flex">
                <label class="w-1/3 mr-2" :for="name">{{config.title}}<template v-if="config.required">*</template></label>
                <div class="flex-1">
                  <beekman-input :id="name"
                                 :type="config.type"
                                 :isValid="validParams[name]"
                                 :config="{}"
                                 :modelValue="params[name]"
                                 :required="config.required"
                                 @update:modelValue="params[name] = $event"
                  />
                </div>
              </div>
            </template>
            <template v-if="config.type === 'textarea'">
              <div class="flex">
                <label class="w-1/3 mr-2" :for="name">{{config.title}}<template v-if="config.required">*</template></label>
                <div class="flex-1">
                  <beekman-textarea :id="name"
                                    :isValid="validParams[name]"
                                    :modelValue="params[name]"
                                    :config="{}"
                                    :required="config.required"
                                    @update:modelValue="params[name] = $event"
                  />
                </div>
              </div>
            </template>
            <template v-if="config.type === 'editor'">
              <label class="inline-block mb-1" :for="name">{{config.title}}</label>
              <beekman-editor :value="params[name]"
                              :data="{}"
                              @update:modelValue="params[name] = $event"
                              :config="config.config"/>
            </template>
            <template v-if="config.type === 'hidden'">
              <input type="hidden" :name="name" :value="params[name]"/>
            </template>
            <template v-if="config.type === 'select'">
              <div class="my-2 w-full flex">
                <label v-if="config.title" class="w-1/3 mt-1 mr-2" :for="config.name">{{config.title}}<template v-if="config.required">*</template></label>
                <beekman-select :label="config.title"
                                :options="config.options"
                                :selected-value="params[name]"
                                @selected="params[name] = $event"
                                :width="config.width"
                                :classes="config.width ? '' : 'flex-1'"
                                :filterable="config.filterable"
                                :resetButton="!config.required"/>
              </div>
            </template>
          </template>
        </template>
      </template>
      <div class="w-full text-right mt-2" v-if="config.acceptBeforeConfirm">
        <label for="accept-confirm">{{config.acceptBeforeConfirmText}}</label> <input id="accept-confirm" v-model="acceptedBeforeConfirm" type="checkbox" class="checkbox ml-1" />
      </div>
    </template>
    <template #footerButtons>
      <div class="flex items-center justify-between">
        <div class="flex justify-end ml-2 w-full">
          <beekman-button styling="secondary" :autofocus="!autoFocusConfirm" @click="unconfirm" :class="{ 'opacity-25': processing }">
            {{ config.closeConfirmButtonText ?? trans('global.no') }}
          </beekman-button>
          <beekman-button styling="danger" :autofocus="autoFocusConfirm" class="ml-2" @click="confirm" :class="{ 'opacity-25': processing }" :disabled="processing || (config.acceptBeforeConfirm && !acceptedBeforeConfirm)">
            <i v-if="processing" class="fas fa-spinner fa-spin mr-3"></i>
            {{ config.confirmButtonText ?? trans('global.yes') }}
          </beekman-button>
        </div>
      </div>
    </template>
  </beekman-confirmation-modal>
  <beekman-fetch-dynamic-modal v-if="confirmed && confirmedOpenDynamicModalRoute != ''"
                               :url="confirmedOpenDynamicModalRoute"
                               @close="unconfirm" />
</template>
<script>
import BeekmanConfirmationModal from '@ui/Components/Modals/ConfirmationModal.vue';
import BeekmanButton from "@ui/Components/Button.vue";
import BeekmanCheckbox from '@ui/Components/Forms/Checkbox.vue';
import BeekmanSelect from '@ui/Components/Select.vue';
import BeekmanInput from '@ui/Components/Forms/Input.vue';
import BeekmanTextarea from '@ui/Components/Forms/Textarea.vue';
import BeekmanEditor from '@ui/Components/Forms/Editor.vue'
import BeekmanFetchDynamicModal from "@ui/Components/Modals/FetchDynamicModal.vue";

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoFocusConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },
    processing: {
      type: Boolean,
      required: false,
      default: false,
    },
    fields: {
      type: Object,
      required: false,
      default: {},
    },
    fieldsData: {
      type: Object,
      default: {}
    },
    routeConfirmData: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
    confirmedOpenDynamicModalRoute: {
      type: String,
      required: false,
      default: '',
    },
    config: {
      type: Object,
      default: [],
    },
  },
  emits: [
    'confirmed',
    'unconfirmed',
    'loading',
  ],
  components: {
    BeekmanFetchDynamicModal,
    BeekmanConfirmationModal,
    BeekmanButton,
    BeekmanCheckbox,
    BeekmanSelect,
    BeekmanInput,
    BeekmanTextarea,
    BeekmanEditor,
  },
  data() {
    let params = [], validParams = [], contentHtml = this.content, formFields = [];
    let loading = false;
    if (Object.keys(this.fields).length) {
      for (let [key, field] of Object.entries(this.fields)) {
        for (let [name, values] of Object.entries(field)) {
          params[name] = values.value;
          validParams[name] = true;
        }
      }
    }
    if (this.routeConfirmData) {
      loading = true;
      this.getConfirmData();
    }
    return {
      params,
      validParams,
      formFields,
      loading,
      contentHtml,
      confirmed: false,
      acceptedBeforeConfirm: false,
    };
  },
  methods: {
    unconfirm() {
      if (!this.processing) {
        this.$emit('unconfirmed');
      }
    },
    confirm() {
      let canConfirm = true;
      if (Object.keys(this.fields).length) {
        for (let [key, field] of Object.entries(this.fields)) {
          for (let [name, values] of Object.entries(field)) {
            if (typeof values.required != 'undefined' && values.required && this.params[name] == '') {
              canConfirm = false;
              this.validParams[name] = false;
            } else {
              this.validParams[name] = true;
            }
          }
        }
      }
      if (this.config.acceptBeforeConfirm && !this.acceptedBeforeConfirm) {
        canConfirm = false;
      }
      if (canConfirm) {
        if (this.confirmedOpenDynamicModalRoute != '') {
          this.confirmed = true;
        } else {
          this.$emit('confirmed', this.params);
        }
      }
    },
    getConfirmData() {
      axios.post(this.routeConfirmData).then((response) => {
        if (response && response.data) {
          if (response.data.fields) {
            this.formFields.push(response.data.fields);
            for (let [name, values] of Object.entries(response.data.fields)) {
              if (values.selectedValue) {
                this.params[name] = values.selectedValue;
                this.validParams[name] = true;
              }
            }
          }
          if (response.data.content) {
            this.contentHtml = response.data.content;
          }
          this.loading = false;
        }
      });
    },
    getFormFields() {
      let fields = [];
      fields.push(this.fields);
      fields.push(this.formFields);
      return fields;
    },
  },
}
</script>
