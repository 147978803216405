<template>
  <div @dblclick="$emit('copyValue', modelValue)">
  <textarea ref="input"
            :class="classes"
            :readonly="disabled || config.disabled"
            :tabindex="index"
            :value="modelValue"
            :rows="config.rows"
            @input="$emit('update:modelValue', $event.target.value)"/>
  </div>
</template>

<script>
import BeekmanFocusField from "@ui/Mixins/FocusField.js";

export default {
  emits: ['update:modelValue', 'error', 'copyValue'],
  props: {
    id: String,
    modelValue: String,
    isValid: [Boolean, Number],
    config: [Array, Object],
    reference: String,
    type: String,
    disabled: [Boolean, Number],
    index: [Number, String],
    data: [Array, Object],
  },
  mixins: [
    BeekmanFocusField,
  ],
  computed: {
    classes() {
      return this.isValid ? '' : 'invalid'
    }
  },
}
</script>
